import { FC, useEffect, useState, useRef } from "react";
import Hls from "hls.js";
import { videoPlayerVariable } from "../../../../../../common/variables/videoPlayer";

interface LiveStreamPlayerProps {
  streamUrl: string;
  setDialogStyles?: boolean;
}

const LiveStreamPlayer: FC<LiveStreamPlayerProps> = ({
  streamUrl,
  setDialogStyles,
}): JSX.Element => {
  const [hlsSettings, setHlsSettings] = useState(() => {
    const savedSettings = localStorage.getItem("hlsSettings");

    let result = {
      liveSyncDuration: 5,
      liveBackBufferLength: 5,
      maxBufferLength: 30,
      maxMaxBufferLength: 60,
      liveDurationInfinity: true,
    };

    try {
      result = JSON.parse(savedSettings ?? "{}");
    } catch (err) {
      result = {
        liveSyncDuration: 5,
        liveBackBufferLength: 5,
        maxBufferLength: 30,
        maxMaxBufferLength: 60,
        liveDurationInfinity: true,
      };
    }

    return result;
  });

  const videoRef = useRef<HTMLVideoElement>(null);

  // Effect to save settings to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("hlsSettings", JSON.stringify(hlsSettings));
  }, [hlsSettings]);

  const handleChange = (event: any) => {
    const { name, value, type, checked } = event.target;

    setHlsSettings((prevSettings: any) => ({
      ...prevSettings,
      [name]: type === "checkbox" ? checked : Number(value),
    }));
  };

  useEffect(() => {
    let hls: Hls | null = null;

    if (!videoRef.current) {
      return;
    }

    if (Hls.isSupported()) {
      hls = new Hls(hlsSettings);

      hls.loadSource(streamUrl);

      hls.attachMedia(videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoRef.current
          ?.play()
          .then(() => {
            videoPlayerVariable(true);
          })
          .catch((error: any) => {
            console.error("Error playing video:", error);
          });
      });

      videoRef.current.addEventListener("seeking", () => {
        if (!videoRef.current || !hls) return;

        const liveEdge = hls.liveSyncPosition ?? videoRef.current.duration;

        if (liveEdge !== Infinity && videoRef.current.currentTime < liveEdge) {
          videoRef.current.currentTime = liveEdge;
        }
      });
    } else {
      if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
        videoRef.current.src = streamUrl;

        videoRef.current.addEventListener("loadedmetadata", () => {
          videoRef.current
            ?.play()
            .then(() => {
              videoPlayerVariable(true);
            })
            .catch((error: any) => {
              console.error("Error playing video:", error);
            });
        });
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [streamUrl]);

  return (
    <>
      <form>
        <div>
          <label>
            Live Sync Duration:
            <input
              type="number"
              name="liveSyncDuration"
              value={hlsSettings.liveSyncDuration}
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Live Back Buffer Length:
            <input
              type="number"
              name="liveBackBufferLength"
              value={hlsSettings.liveBackBufferLength}
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Max Buffer Length:
            <input
              type="number"
              name="maxBufferLength"
              value={hlsSettings.maxBufferLength}
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Max Max Buffer Length:
            <input
              type="number"
              name="maxMaxBufferLength"
              value={hlsSettings.maxMaxBufferLength}
              onChange={handleChange}
            />
          </label>
        </div>
        <div>
          <label>
            Live Duration Infinity:
            <input
              type="checkbox"
              name="liveDurationInfinity"
              checked={hlsSettings.liveDurationInfinity}
              onChange={handleChange}
            />
          </label>
        </div>
      </form>

      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        style={{
          width: "100%",
          height: "100%",
          borderRadius: setDialogStyles ? "0px" : "8px",
          objectFit: "fill",
        }}
      >
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default LiveStreamPlayer;
